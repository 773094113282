export const FOCUS_VISIBLE_CLASSNAME = "focus-visible";
export const IS_HOVERED_CLASSNAME = "is-hovered";
export const IS_HOVERABLE_CLASSNAME = "is-hoverable";
export const IS_ACTIVE_CLASSNAME = "is-active";
export const IS_HIDDEN_CLASSNAME = "is-hidden";
export const IS_SELECTED_CLASSNAME = "is-selected";
export const IS_CHECKED_CLASSNAME = "is-checked";
export const FIELD_IS_CHECKED_CLASSNAME = "field-is-checked";
export const DISABLED_OPACITY = 0.3;
export const LINK_HOVER_OPACITY = 0.6;
/** @deprecated Use appropriate value from `theme.spacing`. */
export const DEFAULT_BOX_PADDING = 16;
export const NAVBAR_ID = "main-navbar";
export const MOMENT_DATE_FORMAT = "DD. MM. YYYY";
export const MOMENT_TIME_FORMAT = "HH:mm";
export const MOMENT_DATETIME_FORMAT = "DD. MM. YYYY, HH:mm";
export const SHOW_INFOBAR = false;
