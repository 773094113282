import { Currency } from "@gql-schema";

const COMMUNITY_PREFIX = "/[zone]";
export type CommunityZone = "komunita" | "moje-domy";
type CommunityParam = { zone: CommunityZone };

import { createRouter } from "@uxf/router";

export interface RouteList {
    index: null;
    sitemap: null;
    "release-notes": null;
    "ie-not-supported": null;
    "under-construction": null;
    "app-under-construction": null;

    // anasoft-zone
    "anasoft-zone/building-elements": { clubId: number };
    "anasoft-zone/inspections": { clubId: number };
    "anasoft-zone/inspection-detail": { clubId: number; inspectionId: number };
    "anasoft-zone/inspection-documents": { clubId: number; inspectionId: number };
    "anasoft-zone/documents": CommunityParam & { clubId: number };
    "anasoft-zone/bank-account": { clubId: number };
    "anasoft-zone/repair-fund": { clubId: number };
    "anasoft-zone/invoices": { clubId: number };
    "anasoft-zone/invoice-detail": { clubId: number; invoiceId: number; currency: Currency };
    "anasoft-zone/balances": { clubId: number };
    "anasoft-zone/requests": { clubId: number };
    "anasoft-zone/request-detail": { clubId: number; requestId: number };
    "anasoft-zone/export-csv": {
        clubId: number;
        entity: "inspections" | "invoices" | "building-elements" | "balances";
        filename: string;
    };

    // auth-zone
    "auth-zone/login": { redirUrl?: string };
    "auth-zone/logout": { redirect?: string }; // BE route
    "auth-zone/registration": { redirUrl?: string };
    "auth-zone/forgotten-password-request": { redirUrl?: string };
    "auth-zone/renew-password": null;
    "auth-zone/email-verify": null;

    // landing-zone
    "landing-zone/neighbours": null;

    "landing-zone/around-me": { smartAddressId?: number; source?: string };
    "landing-zone/chairman-pro": null;
    "landing-zone/chairmen": null;
    "landing-zone/classmates": null;
    "landing-zone/contest-rules": null;
    "landing-zone/faq": { activeTab?: string; faqItem?: string };
    "landing-zone/groups": null;
    "landing-zone/legal-aid": null;
    "landing-zone/new-online-vote": null;
    "landing-zone/organizations": null;
    "landing-zone/promo": null;
    "landing-zone/promo-duties-control": null;
    "landing-zone/promo-overview": null;
    "landing-zone/promo-work-saving": null;
    "landing-zone/service-zone": null;
    "landing-zone/sport": null;

    // footer-zone
    "footer-zone/terms-and-conditions": null;
    "footer-zone/terms-and-conditions-sk": null;
    "footer-zone/memorandum": null;
    "footer-zone/cookies": null;
    "footer-zone/contacts": null;
    "footer-zone/pricing": null;
    "footer-zone/data-protection": null;
    "footer-zone/sitemap-web": null;

    // profile-zone
    "profile-zone/complete-registration": { redirUrl?: string };
    "profile-zone/my-profile": null;
    "profile-zone/personal-settings": null;
    "profile-zone/my-profile-settings-notifications": null;
    "profile-zone/my-profile-group-change-notifications": { clubTypeString: "domy" | "skupiny" };
    "profile-zone/my-profile-settings-club-notifications": { clubId: number };
    "profile-zone/my-profile-settings-news-subscription": null;
    "profile-zone/my-profile-settings-leave-group": null;
    "profile-zone/my-profile-settings-sharing-info": null;
    "profile-zone/my-events": null;
    "profile-zone/notifications": null;
    "profile-zone/my-wall-posts": null;
    "profile-zone/my-event-invitations": null;
    "profile-zone/my-profile-settings-privacy": { clubId: number };

    // messenger-zone
    "messenger-zone/messenger": null;
    "messenger-zone/messenger-thread": { threadId: number };

    // club-zone
    "club-zone/forbidden": null;
    "club-zone/community": null;
    "club-zone/search-club": { term?: string };
    "club-zone/create-club": null;
    "club-zone/clubs-invitations": null;
    "club-zone/club-private-link-invitation": { clubId: number; token: string };
    "club-zone/club-public": { clubId: number; slug: string };

    // my-houses / community
    "club-zone/my-clubs": CommunityParam;
    "club-zone/club-detail": CommunityParam & { clubId: number };
    "club-zone/club-events": CommunityParam & { clubId: number };
    "club-zone/club-event": CommunityParam & { clubId: number; eventId: number };
    "club-zone/club-surveys": CommunityParam & { clubId: number };
    "club-zone/club-members": { clubId: number };
    "club-zone/club-membership-detail": CommunityParam & { clubId: number; membershipId: number };
    "club-zone/club-membership-edit": CommunityParam & { clubId: number; membershipId: number };
    "club-zone/club-settings": CommunityParam & { clubId: number };
    "club-zone/club-settings-house": { clubId: number };
    "club-zone/club-settings-service-company": { clubId: number };
    "club-zone/club-settings-permissions": { clubId: number };
    "club-zone/club-settings-permissions-detail": { clubId: number; permissionsType: string };
    "club-zone/club-members-requests": CommunityParam & { clubId: number };
    "club-zone/club-invitations": CommunityParam & { clubId: number };
    "club-zone/club-admins": CommunityParam & { clubId: number };
    "club-zone/club-statutory-board": { clubId: number };
    "club-zone/club-inspection-board": { clubId: number };
    "club-zone/public-link-invitation": CommunityParam & { clubId: number };
    "club-zone/club-membership-manual-merge": CommunityParam & {
        clubId: number;
        memberId?: number;
        memberName?: string;
    };
    "club-zone/club-membership-auto-merge": CommunityParam & { clubId: number };
    "club-zone/club-feed": CommunityParam & { clubId: number; welcomeModal?: string };
    "club-zone/club-guardian-angel": CommunityParam & { clubId: number };
    "club-zone/club-email-invitations": CommunityParam & { clubId: number };

    // insolvency-zone
    "insolvency-zone/insolvency-and-execution": { clubId: number; activeTab?: string };
    "insolvency-zone/insolvency-list": { clubId: number };
    "insolvency-zone/insolvency-detail": { clubId: number; insolvencyId: number };

    // business-zone
    "business-zone/business-package": { clubId?: number };
    "business-zone/business-package-detail": { clubId: number };
    "business-zone/business-package-order": {
        clubId: number;
        orderType: string;
    };

    // contact-zone
    "contact-zone/contact": CommunityParam & { clubId: number };
    "contact-zone/contact-category": CommunityParam & { clubId: number; contactCategoryId: number };

    // document-zone
    "document-zone/documents": CommunityParam & { clubId: number };
    "document-zone/document-folder": CommunityParam & { clubId: number; folderId: number };
    "document-zone/document-detail": CommunityParam & { clubId: number; documentId: number };

    // photo-zone
    "photo-zone/photo-galleries": CommunityParam & { clubId: number };
    "photo-zone/photo-gallery": CommunityParam & { clubId: number; photoGalleryId: number };

    // house-zone
    "house-zone/house-community": null;
    "house-zone/my-houses": null;
    "house-zone/houses-invitations": null;
    "house-zone/house-request-sent": null;
    "house-zone/create-house-address": null;
    "house-zone/create-demo-house-address": null;
    "house-zone/create-house-address-result": { smartAddressId: number };
    "house-zone/create-house-creation": { smartAddressId: number; trialType?: string };
    "house-zone/create-house-invitation": { clubId: number };
    "house-zone/house-public": { clubId: number; slug: string };
    "house-zone/house-members": { clubId: number; activeTab?: string };
    "house-zone/house-cadastre-update": { clubId: number; cadastreUpdateId: number };
    "house-zone/house-cadastre": { clubId: number; activeTab?: string };
    "house-zone/anasoft-integration": { clubId: number };
    "house-zone/ownership-structure-type": { clubId: number };
    "house-zone/ownership-structure-units": { clubId: number };
    "house-zone/ownership-structure-unit": { clubId: number; unitId?: number };
    "house-zone/my-units": { clubId: number };
    "house-zone/parking-rental": { clubId: number };

    // forms-zone
    "forms-zone/home": { clubId: number; activeTab?: string };
    "forms-zone/person-month-new": { clubId: number };

    // wall-zone
    "wall-zone/club-post-edit": CommunityParam & { clubId: number; postId: number };
    "wall-zone/club-post-detail": CommunityParam & { clubId: number; postId: number };
    "wall-zone/club-survey-detail": CommunityParam & { clubId: number; surveyId: number };

    // defect-zone
    "defect-zone/defects": { clubId: number };
    "defect-zone/defect": { clubId: number; defectId: number };
    "defect-zone/create-defect": { clubId: number };
    "defect-zone/update-defect": { clubId: number; defectId: number };

    // vote-zone
    "vote-zone/vote-home": { clubId: number; activeTab?: string };
    "vote-zone/vote-new-vote": { clubId: number };
    "vote-zone/vote-new-vote-settings": { clubId: number; voteId: number; newVoteStep?: string };
    "vote-zone/vote-voting": { clubId: number; voteId: number; fromMembership?: number };
    "vote-zone/vote-unapproved-documents": { clubId: number; voteId: number };
    "vote-zone/vote-detail": { clubId: number; voteId: number; activeTab?: string };
    "vote-zone/vote-unit-detail": { clubId: number; voteId: number; voteUnitId: number };
    "vote-zone/vote-delegated-vote-selection": { clubId: number; voteId: number };
    "vote-zone/static-delegation-edit": { clubId: number; staticDelegationId?: number };

    // vote-zone-sk
    "vote-zone-sk/vote-home": { clubId: number; activeTab?: string };
    "vote-zone-sk/vote-new-vote": { clubId: number };
    "vote-zone-sk/vote-new-vote-settings": { clubId: number; voteId: number; newVoteStep?: string };
    "vote-zone-sk/vote-voting": { clubId: number; voteId: number; type: string; forMembership?: number };
    "vote-zone-sk/vote-detail": { clubId: number; voteId: number; activeTab?: string };
    "vote-zone-sk/vote-paper-vote-selection": { clubId: number; voteId: number };
    "vote-zone-sk/vote-delegated-vote-selection": { clubId: number; voteId: number };
    "vote-zone-sk/static-delegation": { clubId: number; staticDelegationId?: number };

    // legal-aid-zone
    "legal-aid-zone/legal-aid": { clubId: number };
    "legal-aid-zone/legal-aid-search": { clubId: number; term: string; categoryId?: number };
    "legal-aid-zone/legal-aid-category": { clubId: number; categoryId: number };
    "legal-aid-zone/article-detail": { clubId: number; articleId: number };
    "legal-aid-zone/ask-lawyer": { clubId: number };

    // market-zone
    "market-zone/market": null;
    "market-zone/category": { categoryId: number };
    "market-zone/profession": { categoryId: number; professionId: number };
    "market-zone/offers-newsletter": null;
    "market-zone/special-offer-detail": { offerId: number };
    "market-zone/partner-detail": { slug: string; partnerId: number };

    // magazine-zone
    "magazine-zone/home": null;
    "magazine-zone/all-articles": null;
    "magazine-zone/article": { slug: string; articleId: number };
    "magazine-zone/category": { slug: string; categoryId: number };
    "magazine-zone/search": { term?: string };

    // service-zone
    "service-zone/entry": null;
    "service-zone/campaign-message": { uuid: string };
    "service-zone/registered-houses": { serviceCompanyId: number };
    "service-zone/document-distribution-houses": { serviceCompanyId: number };
    "service-zone/document-distribution": { serviceCompanyId: number; clubId: number };
    "service-zone/employees": { serviceCompanyId: number };
    "service-zone/employees-club-employees": { serviceCompanyId: number; clubId: number };
    "service-zone/settings": { serviceCompanyId: number };
    "service-zone/starlit": { serviceCompanyId: number };
    "service-zone/starlit-membership": { serviceCompanyId: number; externalAddressId: number };
    "service-zone/starlit-units": { serviceCompanyId: number; externalAddressId: number };
    "service-zone/starlit-check-data": { serviceCompanyId: number };
    "service-zone/service-defects": { serviceCompanyId: number };
    "service-zone/service-events": { serviceCompanyId: number };
    "service-zone/service-activities": { serviceCompanyId: number };
    "service-zone/service-insolvency": { serviceCompanyId: number };
    "service-zone/service-cadastre": { serviceCompanyId: number };
    "service-zone/service-wall-posts": { serviceCompanyId: number };
    "service-zone/service-multiple-wallpost-houses-select": { serviceCompanyId: number };
    "service-zone/service-multiple-wallpost-new": { serviceCompanyId: number; housesIds: string };

    // test
    "ui-story-book": null;
    "ui-kit": null;

    // lead
    "lead-zone/chairman-demand-lead": { uuid: string };
    "lead-zone/chairman-demand-success": null;
    "lead-zone/chairman-offer-lead": null;
    "lead-zone/chairman-offer-success": null;
}

export default createRouter<RouteList>({
    index: "/",
    sitemap: "/sitemap.xml",
    "release-notes": "/verze",
    "ie-not-supported": "/ie-neni-podporovan",
    "under-construction": "/web-neni-dostupny",
    "app-under-construction": "/aplikace-neni-dostupna",

    // anasoft-zone
    "anasoft-zone/building-elements": "/moje-domy/[clubId]/konstrukcni-prvky",
    "anasoft-zone/inspections": "/moje-domy/[clubId]/revize",
    "anasoft-zone/inspection-detail": "/moje-domy/[clubId]/revize/[inspectionId]",
    "anasoft-zone/inspection-documents": "/moje-domy/[clubId]/revize/[inspectionId]/dokumenty",
    "anasoft-zone/documents": `${COMMUNITY_PREFIX}/[clubId]/dokumenty/domus`,
    "anasoft-zone/bank-account": "/moje-domy/[clubId]/finance",
    "anasoft-zone/repair-fund": "/moje-domy/[clubId]/finance/fond-oprav",
    "anasoft-zone/invoices": "/moje-domy/[clubId]/finance/fakturace",
    "anasoft-zone/invoice-detail": "/moje-domy/[clubId]/finance/fakturace/[invoiceId]",
    "anasoft-zone/balances": "/moje-domy/[clubId]/finance/zustatky",
    "anasoft-zone/requests": "/moje-domy/[clubId]/hlaseni",
    "anasoft-zone/request-detail": "/moje-domy/[clubId]/hlaseni/[requestId]",
    "anasoft-zone/export-csv": "/api/app/anasoft/[clubId]/export/[entity]/[filename].csv",

    // landing-zone
    "landing-zone/around-me": "/moje-okoli",
    "landing-zone/chairman-pro": "/profesionalni-predseda-svj",
    "landing-zone/chairmen": "/predsedove",
    "landing-zone/classmates": "/spoluzaci",
    "landing-zone/contest-rules": "/pravidlasouteze",
    "landing-zone/faq": "/faq/[[...activeTab]]",
    "landing-zone/groups": "/skupiny",
    "landing-zone/legal-aid": "/pravni-poradna-svj",
    "landing-zone/neighbours": "/sousede",
    "landing-zone/new-online-vote": "/online-hlasovani",
    "landing-zone/organizations": "/spolky",
    "landing-zone/promo": "/promo",
    "landing-zone/promo-duties-control": "/hlidani-povinnosti",
    "landing-zone/promo-overview": "/prehled-o-vsem",
    "landing-zone/promo-work-saving": "/setrime-praci",
    "landing-zone/service-zone": "/spravci",
    "landing-zone/sport": "/sport",

    // footer-zone
    "footer-zone/terms-and-conditions": "/obchodni-podminky",
    "footer-zone/terms-and-conditions-sk": "/obchodne-podmienky",
    "footer-zone/memorandum": "/memorandum",
    "footer-zone/cookies": "/cookies",
    "footer-zone/contacts": "/kontakty",
    "footer-zone/pricing": "/cenik",
    "footer-zone/data-protection": "/ochrana-dat",
    "footer-zone/sitemap-web": "/mapa-stranek",

    // auth-zone
    "auth-zone/login": "/login",
    "auth-zone/logout": "/api/auth/logout", // BE route
    "auth-zone/registration": "/registrace",
    "auth-zone/forgotten-password-request": "/obnoveni-hesla",
    "auth-zone/renew-password": "/zapomenute-heslo/[token]",
    "auth-zone/email-verify": "/overeni-emailu/[token]",

    // profile-zone
    "profile-zone/complete-registration": "/profil/dokonceni-registrace",
    "profile-zone/my-profile": "/profil/nastaveni",
    "profile-zone/personal-settings": "/profil/nastaveni/osobni-nastaveni",
    "profile-zone/my-profile-settings-notifications": "/profil/nastaveni/upozorneni",
    "profile-zone/my-profile-group-change-notifications":
        "/profil/nastaveni/hromadne-nastaveni-upozorneni/[clubTypeString]",
    "profile-zone/my-profile-settings-club-notifications": "/profil/nastaveni/upozorneni/[clubId]",
    "profile-zone/my-profile-settings-news-subscription": "/profil/nastaveni/odbery",
    "profile-zone/my-profile-settings-leave-group": "/profil/nastaveni/opustit-skupinu",
    "profile-zone/my-profile-settings-sharing-info": "/profil/nastaveni/sdileni-informaci",
    "profile-zone/my-events": "/profil/udalosti",
    "profile-zone/notifications": "/profil/prehled-notifikaci",
    "profile-zone/my-wall-posts": "/profil/moje-prispevky",
    "profile-zone/my-event-invitations": "/profil/udalosti/pozvanky",
    "profile-zone/my-profile-settings-privacy": "/profil/nastaveni/soukromi/[clubId]",

    // messenger-zone
    "messenger-zone/messenger": "/zpravy",
    "messenger-zone/messenger-thread": "/zpravy/[threadId]",

    // club-zone
    "club-zone/forbidden": "/komunita/pristup-odepren",
    "club-zone/community": "/komunita",
    "club-zone/search-club": "/komunita/vyhledavani",
    "club-zone/create-club": "/komunita/vytvorit",
    "club-zone/club-public": "/skupina/[clubId]/[slug]",
    "club-zone/my-clubs": "/komunita/moje-skupiny",
    "club-zone/club-members": `/komunita/[clubId]/clenove`,
    "club-zone/clubs-invitations": "/komunita/pozvanky",
    "club-zone/club-private-link-invitation": "/komunita/[clubId]/pozvanka/[token]",

    "club-zone/club-settings-service-company": "/moje-domy/[clubId]/nastaveni/nastaveni-spravni-firmy",
    "club-zone/club-settings-permissions": "/moje-domy/[clubId]/nastaveni/nastaveni-prav",
    "club-zone/club-settings-permissions-detail": "/moje-domy/[clubId]/nastaveni/nastaveni-prav/[permissionsType]",
    "club-zone/club-settings-house": "/moje-domy/[clubId]/nastaveni/nastaveni-domu",
    "club-zone/club-statutory-board": "/moje-domy/[clubId]/statutarni-organ",
    "club-zone/club-inspection-board": "/moje-domy/[clubId]/kontrolni-organ",

    // shared community/my-houses
    "club-zone/club-detail": `${COMMUNITY_PREFIX}/[clubId]/detail`,
    "club-zone/club-events": `${COMMUNITY_PREFIX}/[clubId]/udalosti`,
    "club-zone/club-event": `${COMMUNITY_PREFIX}/[clubId]/udalosti/[eventId]`,
    "club-zone/club-surveys": `${COMMUNITY_PREFIX}/[clubId]/ankety`,
    "club-zone/club-membership-detail": `${COMMUNITY_PREFIX}/[clubId]/profil/[membershipId]`,
    "club-zone/club-membership-edit": `${COMMUNITY_PREFIX}/[clubId]/profil/[membershipId]/editace`,
    "club-zone/club-settings": `${COMMUNITY_PREFIX}/[clubId]/nastaveni`,
    "club-zone/club-members-requests": `${COMMUNITY_PREFIX}/[clubId]/clenove/zadosti`, // TODO rozdelit na /sousede a /clenove
    "club-zone/club-invitations": `${COMMUNITY_PREFIX}/[clubId]/pozvanky`,
    "club-zone/club-admins": `${COMMUNITY_PREFIX}/[clubId]/spravci`,
    "club-zone/public-link-invitation": `${COMMUNITY_PREFIX}/[clubId]/pozvanka`,
    "club-zone/club-membership-manual-merge": `${COMMUNITY_PREFIX}/[clubId]/merge`,
    "club-zone/club-membership-auto-merge": `${COMMUNITY_PREFIX}/[clubId]/auto-merge`,
    "club-zone/club-guardian-angel": `${COMMUNITY_PREFIX}/[clubId]/nastenka/aktivity`,
    "club-zone/club-email-invitations": `${COMMUNITY_PREFIX}/[clubId]/pozvanky/email-pozvanky`,

    // insolvency zone
    "insolvency-zone/insolvency-and-execution": "/moje-domy/[clubId]/insolvence-a-exekuce/[[...activeTab]]",
    "insolvency-zone/insolvency-list":
        "/moje-domy/[clubId]/insolvence-a-exekuce/kontrola-insolvenci/seznam-kontrolovanych-osob",
    "insolvency-zone/insolvency-detail": "/moje-domy/[clubId]/insolvence-a-exekuce/kontrola-insolvenci/[insolvencyId]",

    // business zone
    "business-zone/business-package": "/moje-domy/[clubId]/premium-balicek",
    "business-zone/business-package-detail": "/moje-domy/[clubId]/aktivni-balicek",
    "business-zone/business-package-order": "/moje-domy/[clubId]/objednavka/[orderType]",

    // contact-zone
    "contact-zone/contact": `${COMMUNITY_PREFIX}/[clubId]/kontakty`,
    "contact-zone/contact-category": `${COMMUNITY_PREFIX}/[clubId]/kontakty/[contactCategoryId]`,

    // document-zone
    "document-zone/documents": `${COMMUNITY_PREFIX}/[clubId]/dokumenty`,
    "document-zone/document-folder": `${COMMUNITY_PREFIX}/[clubId]/dokumenty/[folderId]`,
    "document-zone/document-detail": `${COMMUNITY_PREFIX}/[clubId]/dokumenty/detail/[documentId]`,

    // photo-zone
    "photo-zone/photo-galleries": `${COMMUNITY_PREFIX}/[clubId]/fotogalerie`,
    "photo-zone/photo-gallery": `${COMMUNITY_PREFIX}/[clubId]/fotogalerie/[photoGalleryId]`,

    // house-zone
    "house-zone/house-community": "/moje-domy",
    "house-zone/my-houses": "/moje-domy/moje-domy",
    "house-zone/houses-invitations": "/moje-domy/pozvanky",
    "house-zone/house-request-sent": "/moje-domy/zadost-odeslana",
    "house-zone/create-house-address": "/moje-domy/zadejte-adresu",
    "house-zone/create-demo-house-address": "/moje-domy/demo/zadejte-adresu",
    "house-zone/create-house-address-result": "/moje-domy/vysledek-hledani/[smartAddressId]",
    "house-zone/create-house-creation": "/moje-domy/vytvoreni-domu/[smartAddressId]/[[...trialType]]",
    "house-zone/create-house-invitation": "/moje-domy/[clubId]/pozvat",
    "house-zone/house-public": "/dum/[clubId]/[slug]",
    "house-zone/house-members": `/moje-domy/[clubId]/sousede`,
    "house-zone/house-cadastre-update": "/moje-domy/[clubId]/katastr-aktualizace/[cadastreUpdateId]",
    "house-zone/house-cadastre": "/moje-domy/[clubId]/katastr/[[...activeTab]]",
    "house-zone/anasoft-integration": "/moje-domy/[clubId]/nastaveni/integrace",
    "house-zone/ownership-structure-type": "/moje-domy/[clubId]/vlastnicka-struktura/typ-struktury",
    "house-zone/ownership-structure-units": "/moje-domy/[clubId]/vlastnicka-struktura/jednotky",
    "house-zone/ownership-structure-unit": "/moje-domy/[clubId]/vlastnicka-struktura/jednotka",
    "house-zone/my-units": "/moje-domy/[clubId]/moje-priestory",
    "house-zone/parking-rental": "/moje-domy/[clubId]/prenajom-parkovania",

    // form-zone
    "forms-zone/home": "/moje-domy/[clubId]/formulare/prehlad/[[...activeTab]]",
    "forms-zone/person-month-new": "/moje-domy/[clubId]/formulare/pridat-osobomesiace",

    // wall-zone
    "wall-zone/club-post-edit": `${COMMUNITY_PREFIX}/[clubId]/prispevek/[postId]/editace`,
    "wall-zone/club-post-detail": `${COMMUNITY_PREFIX}/[clubId]/prispevek/[postId]`,
    "wall-zone/club-survey-detail": `${COMMUNITY_PREFIX}/[clubId]/anketa/[surveyId]`,

    // defect-zone
    "defect-zone/defects": "/moje-domy/[clubId]/zavady",
    "defect-zone/defect": "/moje-domy/[clubId]/zavada/[defectId]",
    "defect-zone/create-defect": "/moje-domy/[clubId]/nova-zavada",
    "defect-zone/update-defect": "/moje-domy/[clubId]/zavada/[defectId]/uprava",

    // vote-zone
    "vote-zone/vote-home": "/moje-domy/[clubId]/hlasovani/prehled/[[...activeTab]]",
    "vote-zone/vote-new-vote": "/moje-domy/[clubId]/hlasovani/nove-hlasovani",
    "vote-zone/vote-new-vote-settings": "/moje-domy/[clubId]/hlasovani/[voteId]/nastaveni-hlasovani/[newVoteStep]",
    "vote-zone/vote-voting": "/moje-domy/[clubId]/hlasovani/[voteId]/hlasovat/[[...fromMembership]]",
    "vote-zone/vote-unapproved-documents": "/moje-domy/[clubId]/hlasovani/[voteId]/neschvalene-dokumenty",
    "vote-zone/vote-detail": "/moje-domy/[clubId]/hlasovani/[voteId]/[[...activeTab]]",
    "vote-zone/vote-unit-detail": "/moje-domy/[clubId]/hlasovani/[voteId]/jednotka/[voteUnitId]",
    "vote-zone/vote-delegated-vote-selection":
        "/moje-domy/[clubId]/hlasovani/detail-hlasovani/[voteId]/delegovane-hlasovani",
    "vote-zone/static-delegation-edit": "/moje-domy/[clubId]/zmocnujici-dokument/[[...staticDelegationId]]",

    // vote-zone-sk
    "vote-zone-sk/vote-home": "/moje-domy/[clubId]/hlasovanie/prehlad/[[...activeTab]]",
    "vote-zone-sk/vote-new-vote": "/moje-domy/[clubId]/hlasovanie/nove-hlasovanie",
    "vote-zone-sk/vote-new-vote-settings":
        "/moje-domy/[clubId]/hlasovanie/[voteId]/nastavenie-hlasovania/[newVoteStep]",
    "vote-zone-sk/vote-voting": "/moje-domy/[clubId]/hlasovanie/[voteId]/hlasovat/[type]/[[...forMembership]]",
    "vote-zone-sk/vote-detail": "/moje-domy/[clubId]/hlasovanie/[voteId]/[[...activeTab]]",
    "vote-zone-sk/vote-paper-vote-selection":
        "/moje-domy/[clubId]/hlasovanie/detail-hlasovania/[voteId]/pisomne-hlasovanie",
    "vote-zone-sk/vote-delegated-vote-selection":
        "/moje-domy/[clubId]/hlasovanie/detail-hlasovania/[voteId]/delegovane-hlasovanie",
    "vote-zone-sk/static-delegation": "/moje-domy/[clubId]/hlasovanie/splnomocnenie/[[...staticDelegationId]]",

    // legal-aid-zone
    "legal-aid-zone/legal-aid": "/moje-domy/[clubId]/pravni-poradna",
    "legal-aid-zone/legal-aid-search": "/moje-domy/[clubId]/pravni-poradna/vyhledavani",
    "legal-aid-zone/legal-aid-category": "/moje-domy/[clubId]/pravni-poradna/kategorie/[categoryId]",
    "legal-aid-zone/ask-lawyer": "/moje-domy/[clubId]/pravni-poradna/otazka-na-pravnika",
    "legal-aid-zone/article-detail": "/moje-domy/[clubId]/pravni-poradna/clanek/[articleId]",

    // this route must be last community/my-house route
    "club-zone/club-feed": `${COMMUNITY_PREFIX}/[clubId]`,

    // market-zone
    "market-zone/market": "/trziste",
    "market-zone/category": "/trziste/kategorie/[categoryId]",
    "market-zone/profession": "/trziste/kategorie/[categoryId]/profese/[professionId]",
    "market-zone/offers-newsletter": "/trziste/akcni-letak",
    "market-zone/special-offer-detail": "/trziste/akcni-letak/detail/[offerId]",
    "market-zone/partner-detail": "/trziste/partner/[slug]/[partnerId]",

    // magazine-zone
    "magazine-zone/home": "/magazin",
    "magazine-zone/all-articles": "/magazin/vsechny-clanky",
    "magazine-zone/article": "/magazin/clanek/[slug]/[articleId]",
    "magazine-zone/category": "/magazin/kategorie/[slug]/[categoryId]",
    "magazine-zone/search": "/magazin/vyhledavani/[[...term]]",

    // service-zone
    "service-zone/entry": "/spravni-firmy",
    "service-zone/campaign-message": "/spravni-firma/distribuce-dokumentu/[uuid]",
    "service-zone/registered-houses": "/spravni-firma/[serviceCompanyId]/registrovane-domy",
    "service-zone/document-distribution-houses": "/spravni-firma/[serviceCompanyId]/distribuce-dokumentu/domy",
    "service-zone/document-distribution": "/spravni-firma/[serviceCompanyId]/distribuce-dokumentu/domy/[clubId]",
    "service-zone/employees": "/spravni-firma/[serviceCompanyId]/zamestnanci",
    "service-zone/employees-club-employees": "/spravni-firma/[serviceCompanyId]/zamestnanci/dum/[clubId]",
    "service-zone/settings": "/spravni-firma/[serviceCompanyId]/nastaveni",
    "service-zone/starlit": "/spravni-firma/[serviceCompanyId]/starlit",
    "service-zone/starlit-membership": "/spravni-firma/[serviceCompanyId]/nesparovani-lide/[externalAddressId]",
    "service-zone/starlit-units": "/spravni-firma/[serviceCompanyId]/nesparovane-jednotky/[externalAddressId]",
    "service-zone/starlit-check-data": "/spravni-firma/[serviceCompanyId]/kontrola-dat",
    "service-zone/service-defects": "/spravni-firma/[serviceCompanyId]/zavady",
    "service-zone/service-events": "/spravni-firma/[serviceCompanyId]/udalosti",
    "service-zone/service-activities": "/spravni-firma/[serviceCompanyId]/aktivity",
    "service-zone/service-insolvency": "/spravni-firma/[serviceCompanyId]/insolvence",
    "service-zone/service-cadastre": "/spravni-firma/[serviceCompanyId]/katastr",
    "service-zone/service-wall-posts": "/spravni-firma/[serviceCompanyId]/nastenky",
    "service-zone/service-multiple-wallpost-houses-select":
        "/spravni-firma/[serviceCompanyId]/prispevek-do-vice-domu/vyber-domu",
    "service-zone/service-multiple-wallpost-new":
        "/spravni-firma/[serviceCompanyId]/prispevek-do-vice-domu/obsah-prispevku",

    // test
    "ui-story-book": "/storybook",
    "ui-kit": "/uikit",

    // lead
    "lead-zone/chairman-demand-success": "/poptavka-profi-predsedy/dekujeme",
    "lead-zone/chairman-demand-lead": "/poptavka-profi-predsedy/[uuid]",
    "lead-zone/chairman-offer-success": "/nabidka-profi-predsedy/dekujeme",
    "lead-zone/chairman-offer-lead": "/nabidka-profi-predsedy",
});
